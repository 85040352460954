<template>
  <section>
    <div class="text-center m-2"></div>
    <form class="max-w-sm mx-auto font-poppins overflow-x-auto">
      <div class="mb-2 flex justify-center items-center space-x-2">
        <!-- Date Cards -->
        <div v-for="date in sortedDates.slice(0, 4)" :key="date" class="date-card">
          <div @click="onDateClick(date)" class="date-info">
            <div class="text-center bg-gray-50 rounded w-16 pb-0.5">
              <p class="text-xs text-gray-500">{{ getDay(date) }}</p>
              <hr>
              <p class="text-button text-sm font-semibold  pt-0.5">{{ getMonth(date) }}</p>
              <p class="text-2xl font-bold pb-0.5 ">{{ getDate(date) }}</p>
              <p class="text-xs text-input bg-gray-100 ">
                {{ getBookingCount(date) }} Bookings
              </p>
            </div>
          </div>
        </div>
        <!-- Calendar Icon -->
        <div class="calendar-icon" @click.stop="togglCalendar">
          <div class="text-center bg-gray-50 rounded py-8 px-5">
            <i class="fa fa-calendar text-2xl"></i>
          </div>
        </div>
      </div>
      <!-- Calendar View -->
    </form>
    <div v-if="showCalendar" class="flex justify-center relative" @click.stop>
      <PikadayWrapper
        @calendarDateSelected="calendarDateSelected"
        class="absolute"
        :shouldFetchBookings="true"
      />
    </div>
    <hr class="mt-2" />
    <!-- Booking Details Section -->
    <div @click.stop>
      <div v-if="loading" class="flex justify-center items-center mt-24">
        <LoadingAnimator :loading="loading" />
      </div>
      <div v-else-if="!loading && !bookings.length">
        <div class="flex justify-center items-center mt-24">
          <h1 class="text-xl font-normal text-button">
            No bookings for the selected date!
          </h1>
        </div>
      </div>
      <div v-else>
        <div v-for="(booking, index) in bookings" :key="index">
          <BookingCardComp :order="booking" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PikadayWrapper from "@/components/PikadayWrapper.vue";
import BookingCardComp from "./BookingCardComp";
import LoadingAnimator from "@/components/LoadingAnimator";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: {
    PikadayWrapper,
    LoadingAnimator,
    BookingCardComp,
  },
  data() {
    return {
      supplierID: this.$store.state.authentication.supplierID,
      supplierType: this.$store.state.authentication.supplierType,
      dates: this.getPastSevenDays(),
      bookings: [],
      loading: false,
      showCalendar: false,
      selectedDate: this.getPastSevenDays(),
    };
  },
  computed: {
    sortedDates() {
      return [...this.dates].sort((a, b) => new Date(a) - new Date(b));
    },
  
  },
  methods: {
    getPastSevenDays() {
      const today = new Date();
      return Array.from({ length: 4 }, (v, i) => {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        return date.toISOString().split("T")[0];
      }).reverse();
    },
    getDay(date) {
      return new Date(date)
        .toLocaleDateString("en-US", { weekday: "short" })
        .toUpperCase();
    },
    getMonth(date) {
      return new Date(date)
        .toLocaleDateString("en-US", { month: "short" })
        .toUpperCase();
    },
    getDate(date) {
      return new Date(date).getDate();
    },
    async fetchBookings(date) {
      this.loading = true;
      this.selectedDate = date; 
      const { data } = await Suppliers.getSupplierBookings(
        this.supplierID,
        this.supplierType,
        date
      );
      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        this.bookings = data.output[0].booking;
      } else {
        this.bookings = []; 
      }
      this.loading = false;
    },
    calendarDateSelected(date) {
      this.fetchBookings(date);
      this.showCalendar = false;
    },
    getBookingCount(date) {
      return this.bookings.filter((booking) => booking.date === date).length;
    },
    togglCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    onDateClick(date) {
      this.showCalendar = false;
      this.fetchBookings(date);
    },
    handleClickOutside(event) {
      if (this.showCalendar && !this.$el.contains(event.target)) {
        this.showCalendar = false;
      }
    },
  },
  async created() {
    await this.fetchBookings(this.selectedDate);
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.date-card {
  padding: 10px;
  margin-left: 5px;
  cursor: pointer;
  width: 60px;
}

.date-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
}
</style>
