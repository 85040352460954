<template>
  <div class="max-w-sm mx-auto font-poppins overflow-x-auto">
    <div class="mb-2 flex justify-center items-center space-x-2">
      <div
        v-for="date in sortedDates.slice(0, 4)"
        :key="date"
        class="date-card mt-2"
      >
        <div @click="onDateClick(date)" class="date-info">
          <div class="text-center bg-gray-50 rounded w-16 pb-0.5">
            <p class="text-xs text-gray-500">{{ getDay(date) }}</p>
            <hr />
            <p class="text-button text-sm font-semibold pt-0.5">
              {{ getMonth(date) }}
            </p>
            <p class="text-2xl font-bold pb-0.5">{{ getDate(date) }}</p>
            <p class="text-xs text-input bg-gray-100">Bookings</p>
          </div>
        </div>
      </div>

      <div class="calendar-icon" @click.stop="toggleCalendar">
        <div class="text-center bg-gray-50 rounded py-8 px-5 mt-2">
          <i class="fa fa-calendar text-2xl"></i>
        </div>
      </div>
    </div>

    <div v-if="showCalendar" class="flex justify-center relative" @click.stop>
      <PikadayWrapper
        @calendarDateSelected="calendarDateSelected"
        class="absolute"
        :shouldFetchBookings="false"
      />
    </div>

    <div v-if="routes.length === 0" class="mx-6 md:mx-2 mt-24 min-h-80">
      <div class="p-4 text-center text-button text-lg">
        No service for the selected date!
      </div>
    </div>
    <!--Booking route-->
    <div v-else>
      <div v-for="route in routes" :key="route.name" class="mx-6 md:mx-2 mt-6">
        <div class="my-4 flex justify-between">
          <h2 class="md:text-xl text-md font-bold">{{ route.name }}</h2>
        </div>
        <div class="grid gap-1">
          <!--Booking list per route-->
          <div
            v-for="(booking, bookingIndex) in displayedBookings(route)"
            :key="bookingIndex"
            class="bg-white overflow-hidden sm:rounded-lg mb-6 shadow"
          >
            <div
              v-if="booking.bookingStatus.toLowerCase() !== 'completed'"
              class="bg-primary text-input font-semibold px-2 py-2 mb-4 rounded-t-lg"
            >
              <h2 class="text-md font-semibold">{{ booking.hotelName }}</h2>
            </div>
            <div v-if="booking.bookingStatus.toLowerCase() !== 'completed'">
              <div
                class="flex justify-between items-center mb-4 text-gray-500 px-2"
              >
                <div class="flex items-center">
                  <i class="far fa-clock text-gray-500 mr-2"></i>
                  <span class="text-md font-semibold">{{ booking.pickupTime }}</span>
                </div>
                <div class="flex items-center text-md font-semibold">
                  <img :src="couple" class="mr-1 w-4 h-4" alt="" />
                  <span class="mr-2">{{ booking.totalAdults }}</span>
                  <img :src="child" class="mr-1 w-4 h-4" alt="" />
                  <span>{{ booking.totalChildren }}</span>
                </div>
              </div>
              <div class="m-2 flex gap-x-2">
                <!--button status-->
                <button
                  v-if="
                    bookingIndex === 0 &&
                    booking.bookingStatus.toLowerCase() === 'confirmed'
                  "
                  @click="updateBookingStatus(booking, 'COMPLETED', true)"
                  class="mx-[20%] w-full justify-center flex bg-button text-white py-2 rounded text-center"
                >
                  Start Service
                </button>

                <button
                  v-else-if="bookingIndex !== 0"
                  @click="updateBookingStatus(booking, 'COMPLETED', false)"
                  class="mx-[20%] w-full justify-center flex text-white py-2 rounded bg-back text-center"
                >
                  Notify Customer
                </button>
              </div>
              <div class="m-2">
                <div
                  class="w-full justify-center flex text-button py-2 rounded bg-gray-100 text-center"
                  v-if="
                    (bookingIndex === 0 &&
                      booking.bookingStatus.toLowerCase() === 'completed') ||
                    (bookingIndex === 0 &&
                      booking.bookingStatus.toLowerCase() === 'service started')
                  "
                >
                  <p>Service Started</p>
                </div>
              </div>
              <!-- <div
                class="mx-[20%] w-full justify-center flex text-button py-2 rounded bg-gray-100 text-center"
                v-else-if="
                  bookingIndex !== 0 &&
                  booking.bookingStatus.toLowerCase() === 'completed'
                "
              >
                <p>Notified Customer</p>
              </div> -->
            </div>
          </div>
          <!-- Service End Dummy Card -->
          <div class="bg-white overflow-hidden sm:rounded-lg mb-6 shadow">
            <div class="bg-primary text-white px-2 py-5 mb-4 rounded-t-lg">
              <h2 class="text-lg font-semibold"></h2>
            </div>
            <div
              class="flex justify-between items-center mb-4 text-gray-500 px-2"
            >
              <!-- <div class="flex items-center">
                <i class="far fa-clock text-gray-500 mr-2"></i>
                <span class="text-sm">0000</span>
              </div> -->
            </div>
            <div class="m-2 flex gap-x-2">
              <button
                v-if="!isLastBookingCompleted(route)"
                @click="endService(route)"
                class="mx-[20%]  w-full justify-center flex text-white py-2 rounded bg-back text-center"
              >
                Complete Service
              </button>
              <div
                v-else
                class="w-full justify-center flex text-red-500 py-2 rounded bg-gray-100 text-center"
              >
                Service Completed
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="errorMessageStart" class="error-popup text-lg font-medium">
    {{ errorMessageStart }}
  </div>
  <div v-if="errorMessage" class="error-popup text-lg font-medium">
    {{ errorMessage }}
  </div>
  <div v-if="loading" class="loading-container">
    <LoadingAnimator />
  </div>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";
import RepositoryFactory from "../repositories/RepositoryFactory";
import LoadingAnimator from "../components/LoadingAnimator";
import PikadayWrapper from "../components/PikadayWrapper.vue";
import child from "../assets/child.png";
import couple from "../assets/couple.png";

const Suppliers = RepositoryFactory.get("supplier");

export default {
  name: "ShuttleService",
  components: { LoadingAnimator, PikadayWrapper },
  data() {
    return {
      selectedDate: new Date().toISOString().split("T")[0],
      routes: [],
      latitude: "",
      longitude: "",
      loading: false,
      clientIDs: [],
      showCalendar: false,
      dates: this.getPastSevenDays(),
      child,
      couple,
      driverID: "",
      errorMessage: "",
      errorMessageStart: "",
    };
  },
  computed: {
    sortedDates() {
      return [...this.dates].sort((a, b) => new Date(a) - new Date(b));
    },
    displayedBookings() {
      return (route) => {
        return route.viewAll ? route.bookings : route.bookings.slice(0, 12);
      };
    },
  },
  methods: {
    isLastBookingCompleted(route) {
      const lastBooking = route.bookings[route.bookings.length - 1];
      return (
        lastBooking && lastBooking.bookingStatus.toLowerCase() === "completed"
      );
    },
    getPastSevenDays() {
      const today = new Date();
      return Array.from({ length: 4 }, (v, i) => {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        return date.toISOString().split("T")[0];
      }).reverse();
    },
    getDay(date) {
      return new Date(date)
        .toLocaleDateString("en-US", { weekday: "short" })
        .toUpperCase();
    },
    getMonth(date) {
      return new Date(date)
        .toLocaleDateString("en-US", { month: "short" })
        .toUpperCase();
    },
    getDate(date) {
      return new Date(date).getDate();
    },
    calendarDateSelected(date) {
      this.selectedDate = date;
      this.fetchBookings();
      this.showCalendar = false;
    },
    getBookingCount(date) {
      return this.routes.reduce((count, route) => {
        return (
          count +
          route.bookings.filter((booking) => booking.booking_date === date)
            .length
        );
      }, 0);
    },
    clearErrorMessage() {
      setTimeout(() => {
        this.errorMessage = "";
        this.errorMessageStart = "";
      }, 3000);
    },
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    onDateClick(date) {
      this.selectedDate = date;
      this.fetchBookings();
    },
    async fetchBookings() {
      console.log(
        "Fetching bookings for driverID:",
        this.driverID,
        "on date:",
        this.selectedDate
      );
      try {
        this.loading = true;
        const response = await Suppliers.shuttleList(
          this.driverID,
          this.selectedDate,
          this.latitude,
          this.longitude
        );
        console.log("API response:", response);

        const data = response.data;
        console.log("API response data:", data);

        if (data.statuscode === "E_SUCC") {
          const groupedByTitle = data.output.reduce((acc, booking) => {
            const truncatedTitle = booking.title.slice(41);

            if (!acc[truncatedTitle]) {
              acc[truncatedTitle] = {
                name: truncatedTitle,
                viewAll: false,
                bookings: [],
              };
            }

            acc[truncatedTitle].bookings.push({
              title: truncatedTitle,
              hotelName: booking.hotel_name,
              pickupTime: booking.pickup_time,
              totalAdults: booking.total_adult,
              totalChildren: booking.total_child,
              bookingStatus: booking.booking_status,
              clientIDs: booking.client_ids,
              booking_date: booking.booking_date,
            });

            return acc;
          }, {});

          this.routes = Object.values(groupedByTitle);
        } else {
          this.routes = [];
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        this.loading = false;
      }
    },

    async locateMe() {
      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.latitude = position.coords.latitude;
              this.longitude = position.coords.longitude;
              console.log("Location fetched:", this.latitude, this.longitude);
            },
            (error) => {
              console.error("Geolocation error:", error);
              this.latitude = "";
              this.longitude = "";
            }
          );
        } else {
          console.warn("Geolocation is not supported by this browser.");
        }
      } catch (e) {
        console.error("Error fetching location:", e);
        this.latitude = "";
        this.longitude = "";
      }
    },
    async updateBookingStatus(booking, status, isFirstBooking) {
      this.loading = true;
      try {
        const nextStatus =
          status === "SERVICE STARTED" ? "SERVICE STARTED" : "COMPLETED";
        console.log("Latitude:", this.latitude, "Longitude:", this.longitude);

        const response = await Suppliers.shuttleListUpdate(
          nextStatus,
          this.latitude,
          this.longitude,
          this.driverID,
          this.selectedDate,
          booking.pickupTime
        );
        const data = response.data;

        if (data.statuscode === "E_SUCC") {
          // Notify
          if (isFirstBooking === true && booking.clientIDs) {
            await this.notifyCustomer(booking.clientIDs, false);
            this.errorMessageStart = "Status updated successfully & Notified";
            this.clearErrorMessage();
          }
          if (isFirstBooking === false && booking.clientIDs) {
            await this.notifyCustomer(booking.clientIDs, true);
            this.clearErrorMessage();
          }
          await this.fetchBookings();
          console.log("Status updated successfully");
        } else {
          console.error(
            "API response status is not successful:",
            data.statuscode
          );
        }
      } catch (error) {
        console.error("Error updating booking status:", error);
      } finally {
        this.loading = false;
      }
    },

    async notifyCustomer(booking, isFirst) {
      this.loading = true;
      try {
        let clientIDs = booking.clientIDs;
        if (typeof clientIDs === "string") {
          clientIDs = clientIDs.split(",").map((id) => id.trim());
        }
        await Suppliers.notifyCustomer(clientIDs);
        if (isFirst) {
          this.errorMessage = "Customer notified successfully!";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000); // Clear the message after 3 seconds
        }
      } catch (error) {
        console.error("Error notifying customer:", error);
        this.errorMessage = "Failed to notify customer.";
        setTimeout(() => {
          this.errorMessage = "";
        }, 3000); // Clear the message after 3 seconds
      } finally {
        this.loading = false;
      }
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showCalendar = false;
      }
    },

    async endService(route) {
      const lastBooking = route.bookings[route.bookings.length - 1];
      if (lastBooking) {
        this.updateBookingStatus(lastBooking, "COMPLETED");
        this.errorMessage = "Service Successfuly Completed";
        this.clearErrorMessage();
      } else {
        console.error("No bookings found in the route to end the service");
      }
    },
  },
  async created() {
    await this.locateMe();
    const urlParams = new URLSearchParams(window.location.search);
    const track = urlParams.get("track");
    const date = urlParams.get("date");

    if (track) {
      this.driverID = atob(track); // Decode Base64
      console.log("Decoded driverID:", this.driverID);
    }
    if (date) {
      this.selectedDate = date;
      console.log("Selected date:", this.selectedDate);
    }

    await this.fetchBookings();
    document.addEventListener("click", this.handleClickOutside);
  },
};
</script>
<style scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.error-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  color: white;
  transform: translateX(-50%);
  background-color: #a0a0a0;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(219, 224, 220, 0.1);
  z-index: 9999;
}
</style>
