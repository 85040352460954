<template>
  <section class="md:mx-12 font-poppins">
    <div class="flex justify-center m-1">
      <span class="text-input md:text-lg text-md m-2">Booking Ref# {{ bookingService.order_number }}</span>
      <div class="text-gray-600 m-2 md:text-lg text-md ">
        <i v-if="tripDetails.transfer_type === 'Transfer Departure'" class="fa-solid fa-plane-departure"></i>
        <i v-else-if="tripDetails.transfer_type === 'Transfer Arrival'" class="fa-solid fa-plane-arrival"></i>
      </div>
    </div>
    <div class="my-1 font-poppins">
      <DriverDetails :driverDetails="driverDetails" :bookingService="bookingService" />
    </div>
    <div class="text-center m-2 font-poppins">
      <h1 class="text-button font-normal text-xl">{{ tripDetails.bookingDate }}</h1>
    </div>
    <div class="grid grid-cols-3 grid-rows-1 mx-4 mb-2">
      <div class="px-[1px] py-[4px] border border-gray-200 text-center flex flex-col">
        <span class="text-input md:text-md text-sm font-normal">Pickup Time</span>
        <span class="text-black md:text-lg text-md font-normal my-[1px]">{{ tripDetails.pickUpTime }} hours</span>
      </div>
      <div class="p-[4px] border border-gray-200 font-normal text-center flex flex-col">
        <span class="text-input md:text-md text-sm">Total Guest</span>
        <span class="text-black md:text-lg text-md font-normal my-[1px]">{{ tripDetails.totalGuests }}</span>
      </div>
      <div class="py-[4px] border border-gray-200 font-normal text-center flex flex-col">
        <span class="text-input md:text-md text-sm">Status</span>
        <span :class="['text-button', 'text-md', 'md:text-lg', 'font-normal', 'mt-1', statusColorClass]">{{ tripDetails.status }}</span>
      </div>
    </div>
    <div class="pl-4 text-start flex flex-col">
      <span class="text-input font-normal md:text-md text-sm">Pick up Location</span>
      <span class="text-black md:text-lg text-md font-normal mb-2">{{ tripDetails.pickUpLocation }}</span>
    </div>
    <div class="pl-4 text-start flex flex-col">
      <span class="text-input font-normal md:text-md text-sm">Drop off Location</span>
      <span class="text-black md:text-lg text-md font-normal mb-2">{{ tripDetails.dropOffLocation }}</span>
    </div>
    <div class="pl-4 text-start flex flex-col">
      <span class="text-input font-normal md:text-md text-sm">Remarks:</span>
      <span class="text-black md:text-lg text-md font-normal mb-2">{{ remarksData }}</span>
    </div>
    <div class="grid grid-cols-1 grid-rows-1 mx-4 m-2">
      <div class="p-[1px] border border-gray-200 text-center flex flex-col">
        <span class="text-input md:text-md text-sm font-normal">Flight Number</span>
        <span class="text-black md:text-lg text-md font-normal my-[1px]">{{ tripDetails.flightNumber }}</span>
      </div>
    </div>
    <div class="grid grid-cols-3 grid-rows-1 mx-4 mb-2">
      <div class="p-[1px] border border-gray-200 font-normal text-center flex flex-col">
        <span class="text-input md:text-md text-sm">Flight Status</span>
        <span class="text-black md:text-lg text-md font-normal my-[1px]">{{ bookingService.flight_status == '' || bookingService.flight_status == null ? 'N/A' : bookingService.flight_status }}</span>
      </div>
      <div class="p-[1px] border border-gray-200 text-center flex flex-col">
        <span class="text-input md:text-md text-sm font-normal">Scheduled Time</span>
        <span class="text-black md:text-lg text-md font-normal my-[1px]">{{ bookingService.flight_scheduled_time == '' ? 'N/A' : bookingService.flight_scheduled_time && "hours"}} </span>
      </div>
      <div class="p-[1px] border border-gray-200 font-normal text-center flex flex-col">
        <span class="text-input md:text-md text-sm">Actual Time</span>
        <span class="text-black md:text-lg text-md font-normal my-[1px]">{{ bookingService.flight_actual_time == '' ? 'N/A' : bookingService.flight_actual_time  && "hours" }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import DriverDetails from '@/components/driver/DriverDetails.vue';
import Pusher from 'pusher-js';
export default {
  name: "TripDetails",
  components: { DriverDetails },
  props: {
    driverDetails: Object,
    tripDetails: Object,
    bookingService: Object,
  },
  data(){
    return{
      remarksData: '',
    }
  },
  mounted(){
    this.updateRemarks(this.bookingService.remarks)
  },
  computed: {
    statusColorClass() {
      switch (this.tripDetails.status) {
        case 'NEW':
          return 'text-indigo-500';
        case 'COMPLETED':
          return 'text-button';
        case 'REJECTED':
          return 'text-rose-600';
        case 'COMPLETED*':
          return 'text-rose-600';
        default:
          return 'text-orange-500';
      }
    },
  },
  created() {
    this.initializePusher();
  },
  methods: {
  initializePusher() {
    const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      encrypted: true,
    });

    pusher.connection.bind('connected', () => {
      console.log('Pusher connected successfully');
    });

    pusher.connection.bind('error', (err) => {
      console.error('Pusher connection error:', err);
    });

    const channel = pusher.subscribe(process.env.VUE_APP_PUSHER_CHANNEL);
channel.bind_global((eventName, data) => {
  if (data && data.booking_id) {
    try {
      let bookingIdObject = JSON.parse(data.booking_id);
      let bookingId = bookingIdObject.booking_id;

      // Accessing the remarks from the booking_id object
      let remarksObject = JSON.parse(data.booking_id);
      let remarks = remarksObject.remarks;

      // Accessing the ID from the route parameters
      let orderNumber = this.$route.params.code;
      if (orderNumber === bookingId) {
      
          this.remarksData = remarks
        
        console.log(`Received event '${eventName}' with data:`, data);
      } else {
        console.log(`Received event '${eventName}' but booking_id did not match`);
      }
    } catch (error) {
      console.error("Error parsing booking_id:", error);
    }
  } else {
    console.log(`Received event '${eventName}' with invalid or missing booking_id`);
  }
});



  },
  updateRemarks(val){
    console.log(val);
if(val){
  this.remarksData =val
}
else{
  console.log(val , "not available");
}
  }
},
};
</script>
